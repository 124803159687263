@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --primary-color: #607eaa;
  --secondary-color: #fffae7;
  --other-color: #efefef;
  --dark-other-color: #e0e0e0;
  --black-color: black;
  --white-color: white;
  --disabled-text: #707070;
  --separator-color: #8199b450;
  --separator-color: #d3d3d3;
  --shadow-color: #00000030;
  --transition: 0.2s;
  --btns-size: 30px;
  --content-start-position: 85px;
  --content-end-position: 35px;
  --spacing: 10px;
  --scale: 0.9;
}

/* %%%%%% [ General ] %%%%%% */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: var(--secondary-color);
  height: 100%;
  width: 100%;
}

table {
  margin: 0 !important;
}

a {
  text-decoration: none;
  -webkit-user-drag: none;
  color: var(--color-5);
}

a:hover {
  color: inherit;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

/* %%%%%% [ Icons ] %%%%%% */

.reactIcon {
  vertical-align: middle;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* %%%%%% [ Prevent Selection ] %%%%%% */

.unselectable {
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* %%%%%% [ Categories ] %%%%%% */

/* .app {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
} */

.app > .sidepanel {
  border: 1px solid black;
  position: relative;
  flex-basis: 30%;
  padding: 10px;
  background-color: var(--other-color);
}

.app > .sidepanel .createBtn {
  color: var(--primary-color);
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 13px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  border: none;
}

.app > .sidepanel .createBtn:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.app > .sidepanel .createBtn .reactIcon {
  margin-right: 6px;
  font-size: 20px;
}

.app > .sidepanel ul li {
  display: flex;
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: var(--transition);
}

.app > .sidepanel ul li:hover {
  background-color: var(--dark-other-color);
}

.app > .sidepanel ul li._active {
  background-color: var(--primary-color);
  cursor: default;
  color: #fff;
}

.app > .sidepanel ul li .reactIcon {
  margin-right: 10px;
}

/* .app > .content {
  border: 1px solid black;
  position: relative;
  flex-basis: 70%;
  background-color: var(--secondary-color);
} */

/* %%%%%% [ Header ] %%%%%% */

.tasks header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-inline: calc(var(--spacing) * 2);
  height: var(--content-start-position);
  border-bottom: 2px solid var(--separator-color);
  background-color: var(--separator-color);
}

.tasks header .title {
  display: flex;
}

.tasks header .title img {
  height: 16px;
  position: absolute;
  left: 170px;
  top: 22px;
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.tasks header .title h1 {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 30px;
}

.tasks header .actions .search-trigger {
  font-size: calc(var(--btns-size) - 7px);
  padding: 4px;
  margin-right: var(--spacing);
  transition: var(--transition);
  border: none;
  color: var(--black-color);
}

.tasks header .actions .search-trigger:active {
  scale: var(--scale);
}

.tasks header .actions .filteredTasks-trigger {
  background-color: var(--other-color);
  font-size: 15px;
  color: var(--black-color);
  box-shadow: none;
  border-color: transparent;
}

.tasks header .actions .filteredTasks-trigger:focus {
  border-color: var(--bs-primary);
}

.tasks header .actions .createTask-trigger {
  font-size: var(--btns-size);
  padding: 2px;
  margin-left: var(--spacing);
  border-radius: 50%;
  transition: var(--transition);
}

.tasks header .actions .createTask-trigger:active {
  scale: var(--scale);
}

/* %%%%%% [ Content ] %%%%%% */

.tasks .table-container {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: var(--content-start-position);
  bottom: var(--content-end-position);
  overflow: auto;
  padding: var(--spacing);
}

.tasks .searchBar {
  display: block;
  width: 100%;
  margin-bottom: var(--spacing);
  transition: var(--transition);
  box-shadow: none;
  border: 1px solid var(--shadow-color);
}

.tasks .searchBar:focus {
  border-color: var(--bs-primary);
}

.tasks table tr td {
  padding: var(--spacing) calc(var(--spacing) / 2);
  vertical-align: middle;
  text-align: center;
}

table tr:last-of-type {
  border-color: transparent;
}

.tasks .tasksItem .completed-container {
  width: 45px;
  min-width: 45px;
  padding-inline: var(--spacing);
}

.tasks .tasksItem .completed-container .completeTask-trigger {
  padding: 0;
  width: calc(var(--btns-size) - 5px);
  height: calc(var(--btns-size) - 5px);
  border-radius: 50%;
  transition: var(--transition);
  margin: 0px;
}

.tasks .tasksItem .completed-container .completeTask-trigger .reactIcon {
  font-size: calc(var(--btns-size) - 5px);
  color: var(--secondary-color);
  opacity: 0;
}

.tasks
  .tasksItem.completed
  .completed-container
  .completeTask-trigger
  .reactIcon {
  opacity: 1;
}

.tasks .tasksItem .completed-container .completeTask-trigger:hover {
  background-color: unset;
}

.tasks .tasksItem.completed .completed-container .completeTask-trigger:hover {
  background-color: var(--bs-success);
}

.tasks .tasksItem .completed-container .completeTask-trigger:hover .reactIcon {
  color: var(--bs-success);
  opacity: 1;
}

.tasks
  .tasksItem.completed
  .completed-container
  .completeTask-trigger:hover
  .reactIcon {
  color: var(--white-color);
}

.tasks .tasksItem .text-container {
  text-align: center;
  font-weight: 500;
  padding-inline: calc(var(--spacing) * 2);
  font-size: 16px;
}

.tasks .tasksItem.completed .text-container {
  color: var(--disabled-text);
}

.tasks .tasksItem .editRemove-container {
  width: 90px;
  min-width: 90px;
  padding-inline: var(--spacing);
}

.tasks .tasksItem .editRemove-container button {
  width: var(--btns-size);
  height: var(--btns-size);
  padding: 0;
  margin: 0px;
}

.tasks .tasksItem .editRemove-container button:nth-child(2) {
  margin-left: 8px;
}

.tasks .form-container {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
  padding-inline: var(--spacing);
  width: 100%;
}

/* %%%%%% [ Footer ] %%%%%% */

.tasks footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  height: var(--content-end-position);
  border-top: 2px solid var(--separator-color);
  background-color: var(--separator-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
}

.tasks footer p .reactIcon {
  margin-right: 4px;
  display: inline-block;
  margin-top: -2px;
}

.tasks footer p a {
  font-weight: bold;
  opacity: 0.8;
}

.tasks footer p a:hover {
  opacity: 1;
  text-decoration: underline;
}

/* %%%%%% [ Responsive ] %%%%%% */

@media only screen and (max-width: 600px) {
  :root {
    --btns-size: 28px;
    --content-start-position: 60px;
    --content-end-position: 30px;
    --spacing: 7.5px;
  }

  .tasks header .title h1 {
    font-size: 22px;
  }

  .tasks header .title img {
    height: 11px;
    left: 125px;
    top: 16px;
  }

  .tasks header .actions .filteredTasks-trigger {
    font-size: 12px;
  }

  .tasks .tasksItem .completed-container {
    width: 40px;
    min-width: 40px;
  }

  .tasks .tasksItem .text-container {
    font-size: 14px;
  }

  .tasks .tasksItem .editRemove-container {
    width: 82px;
    min-width: 82px;
  }

  .tasks .tasksItem .editRemove-container button:nth-child(2) {
    margin-left: 5px;
  }

  .tasks footer {
    font-size: 13px;
  }
}

@media only screen and (max-width: 400px) {
  :root {
    --btns-size: 25px;
    --content-start-position: 50px;
    --content-end-position: 27px;
    --spacing: 5px;
  }

  .tasks header .title h1 {
    font-size: 17px;
  }

  .tasks header .title img {
    height: 8px;
    left: 96px;
    top: 13px;
  }

  .tasks header .actions .filteredTasks-trigger {
    font-size: 11px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.3rem;
    padding-right: 1.5rem;
    background-position: right 0.25rem center;
    background-size: 13px 10px;
  }

  .tasks .tasksItem .completed-container {
    width: 30px;
    min-width: 30px;
  }

  .tasks .tasksItem .text-container {
    font-size: 12px;
  }

  .tasks .tasksItem .editRemove-container {
    width: 66px;
    min-width: 66px;
  }

  .tasks .tasksItem .editRemove-container button {
    font-size: 15px;
  }

  .tasks .form-container form input,
  .tasks .searchBar {
    height: 30px;
    font-size: 13px;
    padding-inline: 5px;
  }

  .tasks .form-container form button {
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .tasks footer {
    font-size: 11px;
  }
}
